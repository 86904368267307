// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__MultipleCombobox__container{display:flex;height:auto;min-height:var(--JDS-INPUT-FIELD-HEIGHT-INTERNAL);width:100%}.jut__MultipleCombobox__valueContainer{cursor:pointer;display:flex;flex-grow:1;gap:var(--JDS-INPUT-FIELD-MULTISELECT-GAP-INTERNAL);overflow:hidden}.jut__MultipleCombobox__button,.jut__MultipleCombobox__value{cursor:pointer}.jut__MultipleCombobox__button,.jut__MultipleCombobox__input,.jut__MultipleCombobox__value{background:transparent;border:none;outline:none}.jut__MultipleCombobox__input{color:var(--JDS-INPUT-FIELD-TEXT-COLOR-TEXT-INTERNAL);cursor:text;flex-grow:1;font-family:var(--JDS-FONT-LABEL-MEDIUM-FONT-FAMILY-INTERNAL);font-size:var(--JDS-FONT-LABEL-MEDIUM-FONT-SIZE-INTERNAL);font-weight:var(--JDS-FONT-LABEL-MEDIUM-FONT-WEIGHT-INTERNAL);letter-spacing:var(--JDS-FONT-LABEL-MEDIUM-LETTER-SPACING-INTERNAL);line-height:var(--JDS-FONT-LABEL-MEDIUM-LINE-HEIGHT-INTERNAL);min-height:auto;padding:0;text-decoration:var(--JDS-FONT-LABEL-MEDIUM-TEXT-DECORATION-INTERNAL);text-transform:var(--JDS-FONT-LABEL-MEDIUM-TEXT-CASE-INTERNAL)}.jut__MultipleCombobox__disabled{cursor:not-allowed}.jut__MultipleCombobox__button{align-items:center;color:var(--JDS-INPUT-FIELD-TEXT-COLOR-TEXT-INTERNAL);display:flex;padding:0}", "",{"version":3,"sources":["webpack://./../../../../../../src/fields/dropdowns/internal/MultipleComboboxInput/MultipleCombobox.module.scss","webpack://./../../platform/@jutro/components/fields/dropdowns/internal/MultipleComboboxInput/MultipleCombobox.module.css"],"names":[],"mappings":"AAAA,kCAEE,YAAA,CACA,WAAA,CACA,iDAAA,CAHA,UCIF,CDEA,uCAKE,cAAA,CAJA,YAAA,CAEA,WAAA,CACA,mDAAA,CAFA,eCIF,CDEA,6DAKE,cCCF,CDEA,2FANE,sBAAA,CACA,WAAA,CACA,YCoBF,CDhBA,8BAQE,qDAAA,CAJA,WAAA,CACA,WAAA,CAIA,6DAAA,CACA,yDAAA,CACA,6DAAA,CAIA,mEAAA,CAHA,6DAAA,CALA,eAAA,CADA,SAAA,CAOA,qEAAA,CACA,8DCEF,CDEA,iCACE,kBCCF,CDEA,+BAEE,kBAAA,CAEA,qDAAA,CAHA,YAAA,CAEA,SCEF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jut__MultipleCombobox__container",
	"valueContainer": "jut__MultipleCombobox__valueContainer",
	"button": "jut__MultipleCombobox__button",
	"value": "jut__MultipleCombobox__value",
	"input": "jut__MultipleCombobox__input",
	"disabled": "jut__MultipleCombobox__disabled"
};
export default ___CSS_LOADER_EXPORT___;
