// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jut__CurrencyInput__input{-moz-appearance:textfield;appearance:textfield;background-color:transparent;border:none;color:var(--JDS-INPUT-FIELD-TEXT-COLOR-TEXT-INTERNAL);height:100%;outline:none;padding:0;position:relative;width:100%}.jut__CurrencyInput__input[disabled]{cursor:not-allowed}.jut__CurrencyInput__input::-webkit-inner-spin-button,.jut__CurrencyInput__input::-webkit-outer-spin-button{appearance:none;margin:0}.jut__CurrencyInput__inputOverrides{align-items:center;display:flex;gap:var(--JDS-INPUT-FIELD-GAP-INTERNAL);position:relative}.jut__CurrencyInput__left{padding-left:0}.jut__CurrencyInput__right{padding-right:0}", "",{"version":3,"sources":["webpack://./../../../../src/fields/CurrencyInput/CurrencyInput.module.scss","webpack://./../../platform/@jutro/components/fields/CurrencyInput/CurrencyInput.module.css"],"names":[],"mappings":"AAEA,2BAoBE,yBAAA,CAAA,oBAAA,CAdA,4BAAA,CAHA,WAAA,CAIA,qDAAA,CAFA,WAAA,CAHA,YAAA,CAMA,SAAA,CAPA,iBAAA,CAGA,UCOF,CDDE,qCACE,kBCGJ,CDAE,4GAEE,eAAA,CACA,QCCJ,CDKA,oCAGE,kBAAA,CADA,YAAA,CAEA,uCAAA,CAHA,iBCCF,CDKA,0BACE,cCFF,CDKA,2BACE,eCFF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "jut__CurrencyInput__input",
	"inputOverrides": "jut__CurrencyInput__inputOverrides",
	"left": "jut__CurrencyInput__left",
	"right": "jut__CurrencyInput__right"
};
export default ___CSS_LOADER_EXPORT___;
